import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { DeviationDelay } from '@/types/deviation-delay'

export function updateDeviationDelay(
  body: Partial<DeviationDelay>,
  deviationDelayUuid: string
): Promise<AxiosResponse<DeviationDelay>> {
  return instance.put(`/deviation-delay/${deviationDelayUuid}`, body)
}
