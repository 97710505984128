import { instance } from '@/services/avik-api-gateway'
import { DeviationDelayProposal } from '@/types/deviation-delay-proposal'
import { AxiosResponse } from 'axios'

interface FetchParams {
  from: string
  to: string
}

let abortController: AbortController

export function getDeviationDelayProposals(
  params: FetchParams
): Promise<AxiosResponse<DeviationDelayProposal[]>> {
  if (abortController) {
    abortController.abort()
    abortController = new AbortController()
  } else {
    abortController = new AbortController()
  }

  return instance.get(`/deviation-delay-proposal`, {
    params,
    signal: abortController.signal,
  })
}
