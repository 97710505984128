<template>
  <q-card style="min-width: 400px">
    <q-card-section class="row items-center text-h6">
      Ta bort avvikelse
    </q-card-section>
    <q-card-section>
      <p>Är du säker på att du vill ta bort avvikelsen?</p>
    </q-card-section>
    <q-card-actions align="right">
      <q-btn
        flat
        label="Avbryt"
        @click="deleteModal.closeModal"
        type="button"
        :disable="loading.delete"
      />
      <q-btn
        label="Ta bort"
        type="button"
        color="red"
        :loading="loading.delete"
        @click="onConfirm"
      />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { useDeviationVehicle } from '@/composable/useDeviationVehicle'
import { useDeviationDelay } from '@/composable/useDeviationDelay'
import { UseModal } from '@/composable/useModal'
import { computed, defineComponent, inject } from 'vue'
import { useDeviationCancel } from '@/composable/useDeviationCancel'
import { useDeviationCrew } from '@/composable/useDeviationCrew'
import { useDeviationDisruption } from '@/composable/useDeviationDisruption'
import { useDeviationVehicleProposal } from '@/composable/useDeviationVehicleProposal'
import { useEvent } from '@/composable/useEvent'
import { format } from 'date-fns'
import { useDeviationCancelProposal } from '@/composable/useDeviationCancelProposal'
import { useDeviationDelayProposal } from '@/composable/useDeviationDelayProposal'

export default defineComponent({
  name: 'DeviationDeleteModal',

  setup() {
    const {
      loading: loadingStateDeviationVehicle,
      remove: removeDeviationVehicle,
    } = useDeviationVehicle()

    const {
      loading: loadingStateDeviationDelay,
      remove: removeDeviationDelay,
    } = useDeviationDelay()
    const {
      loading: loadingStateDeviationCancel,
      remove: removeDeviationCancel,
    } = useDeviationCancel()
    const {
      loading: loadingStateDeviationDisruption,
      remove: removeDeviationDisruption,
    } = useDeviationDisruption()
    const { loading: loadingStateDeviationCrew, remove: removeDeviationCrew } =
      useDeviationCrew()
    const deleteModal = inject<UseModal<{ uuid: string }>>(
      'deviation-delete-modal'
    )

    const { data: event } = useEvent()

    const { fetchAll: fetchAllDeviationVehicleProposal } =
      useDeviationVehicleProposal()

    const { fetchAll: fetchAllDeviationCancelProposal } =
      useDeviationCancelProposal()

    const { fetchAll: fetchAllDeviationDelayProposal } =
      useDeviationDelayProposal()

    async function onConfirm() {
      if (!deleteModal?.state.data) {
        throw new Error('Ingen uuid i deleteModal data parametrar')
      }

      if (deleteModal.state.mode === 'deviationVehicle') {
        await removeDeviationVehicle(deleteModal.state.data.uuid)
        if (event.value) {
          const period = {
            from: format(new Date(event.value.start), 'yyyy-MM-dd'),
            to: format(new Date(event.value.start), 'yyyy-MM-dd'),
          }
          fetchAllDeviationVehicleProposal(period)
        }
      }

      if (deleteModal.state.mode === 'deviationDelay') {
        await removeDeviationDelay(deleteModal.state.data.uuid)
        if (event.value) {
          const period = {
            from: format(new Date(event.value.start), 'yyyy-MM-dd'),
            to: format(new Date(event.value.start), 'yyyy-MM-dd'),
          }
          fetchAllDeviationDelayProposal(period)
        }
      }

      if (deleteModal.state.mode === 'deviationCancel') {
        await removeDeviationCancel(deleteModal.state.data.uuid)
        if (event.value) {
          const period = {
            from: format(new Date(event.value.start), 'yyyy-MM-dd'),
            to: format(new Date(event.value.start), 'yyyy-MM-dd'),
          }
          fetchAllDeviationCancelProposal(period)
        }
      }

      if (deleteModal.state.mode === 'deviationCrew') {
        removeDeviationCrew(deleteModal.state.data.uuid)
      }

      if (deleteModal.state.mode === 'deviationDisruption') {
        removeDeviationDisruption(deleteModal.state.data.uuid)
      }

      deleteModal.closeModal()
    }

    const loading = computed(() => {
      return (
        loadingStateDeviationVehicle.value.delete ||
        loadingStateDeviationDelay.value.delete ||
        loadingStateDeviationCancel.value.delete ||
        loadingStateDeviationCrew.value.delete ||
        loadingStateDeviationDisruption.value.delete
      )
    })

    return {
      deleteModal,
      loading,
      onConfirm,
    }
  },
})
</script>
