import { instance } from '@/services/avik-api-gateway'
import { SearchTrain } from '@/types/search-train'
import { AxiosResponse, CancelToken } from 'axios'

export interface SearchTrainParams {
  tnr: number
  date: string
}

export function searchTrain(
  params: SearchTrainParams,
  cancelToken: CancelToken
): Promise<AxiosResponse<SearchTrain>> {
  return instance.get(`/search/train`, {
    cancelToken,
    params,
  })
}

export function searchTrainDirect(
  params: SearchTrainParams
): Promise<AxiosResponse<SearchTrain>> {
  return instance.get(`/search/train`, {
    params,
  })
}
