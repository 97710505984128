import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { createDeviationDelay } from '@/api/deviation-delay/createDeviationDelay'
import { DeviationDelay } from '@/types/deviation-delay'
import { updateDeviationDelay } from '@/api/deviation-delay/updateDeviationDelay'
import { useEvent } from '@/composable/useEvent'
import { deleteDeviationDelay } from '@/api/deviation-delay/deleteDeviationDelay'
import { format, sub } from 'date-fns'
import { getDeviationDelays } from '@/api/deviation-delay/getDeviationDelays'

export interface DeviationDelayModel {
  uuid: string
  departureDate: Date
  delayMinutes: number
  delayLocation: string
  delayAdvertisedTime: Date
  delayType: 'ank' | 'avg'
  advertised: number | null
  description: string
  eventUuid: string
  eventName: string | null
  eventId: number | null
  eventIsOpen: boolean | null
  eventDate: string | null
  delayReason: string | null
  delayReasonDescription: string | null
  bana?: string
}

interface UseDeviationDelay {
  data: Ref<DeviationDelay[]>
  remove: (uuid: string) => Promise<null>
  add: (body: DeviationDelayModel) => Promise<DeviationDelay>
  update: (body: Partial<DeviationDelay>) => Promise<DeviationDelay>
  loading: ComputedRef<Loading>
  fetchAll: (params: FetchAllParams) => Promise<DeviationDelay[]>
  period: Ref<Period>
  setDefaultPeriod: () => void
}

interface FetchAllParams {
  from: string
  to: string
}

interface Period {
  from: string
  to: string
}

function createDefaultPeriod() {
  return {
    from: format(sub(new Date(), { days: 2 }), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd'),
  }
}

const period = ref<Period>(createDefaultPeriod())
const data = ref<DeviationDelay[]>([])
const { state } = useLoading()

export function useDeviationDelay(): UseDeviationDelay {
  const { data: event } = useEvent()

  function setDefaultPeriod() {
    period.value = createDefaultPeriod()
  }

  async function fetchAll(fetchParams: FetchAllParams) {
    state.getAll = true
    return new Promise<DeviationDelay[]>((resolve, reject) => {
      getDeviationDelays(fetchParams)
        .then(({ data: deviationDelays }) => {
          if (deviationDelays !== undefined) {
            state.getAll = false
          }
          data.value = deviationDelays || []
          resolve(deviationDelays || [])
        })
        .catch(reject)
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteDeviationDelay(uuid)
        .then(() => {
          if (event.value) {
            event.value.deviationDelays = event.value.deviationDelays.filter(
              (item) => item.uuid !== uuid
            )
          }
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: DeviationDelayModel) {
    state.create = true
    return new Promise<DeviationDelay>((resolve, reject) => {
      createDeviationDelay(body)
        .then(({ data: deviationDelay }) => {
          if (event.value) {
            event.value.deviationDelays.push(deviationDelay)
          }

          data.value.push({
            ...deviationDelay,
            eventName: body.eventName || null,
            eventId: body.eventId || null,
            eventIsOpen: body.eventIsOpen || null,
            eventDate: body.eventDate || null,
          })
          resolve(deviationDelay)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Partial<DeviationDelay>) {
    state.update = true

    const parsedBody = Object.assign({}, body)
    if (parsedBody.uuid) delete parsedBody.uuid

    return new Promise<DeviationDelay>((resolve, reject) => {
      updateDeviationDelay(parsedBody, body?.uuid || 'missing')
        .then(({ data: updateDeviationDelay }) => {
          if (event.value) {
            event.value.deviationDelays = event.value.deviationDelays.map(
              (deviationDelay) => {
                if (deviationDelay.uuid === body?.uuid) {
                  return updateDeviationDelay
                }

                return deviationDelay
              }
            )
          }

          data.value = data.value.map((deviationDelay) => {
            if (deviationDelay.uuid === body?.uuid) {
              return {
                ...updateDeviationDelay,
                eventName: body.eventName || null,
                eventId: body.eventId || null,
                eventIsOpen: body.eventIsOpen || null,
                eventDate: body.eventDate || null,
              }
            }

            return deviationDelay
          })

          resolve(updateDeviationDelay)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  return {
    data,
    remove,
    add,
    update,
    loading: computed(() => state),
    fetchAll,
    period,
    setDefaultPeriod,
  }
}
