
import QSelect from 'quasar/src/components/select/QSelect.js';
import { defineComponent, ref, PropType, computed } from 'vue'

export default defineComponent({
  name: 'AppFieldSelect',

  emits: ['update:modelValue'],

  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    multiple: Boolean,
    disable: Boolean,
    loading: Boolean,
    label: String,
    filterCallback: {
      type: Function,
      required: false,
    },
    fillInput: {
      type: Boolean,
      default: true,
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    modelValue: null,
    optionLabel: [String, Function],
    options: {
      type: Array as PropType<Array<string | { [p: string]: string }>>,
      default: () => [],
    },
    filterAutoSelect: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    })

    const filteredOptions = ref(props.options)

    const filterFn = async (
      val: string,
      update: (Fn: () => void, RefFn: (ref: QSelect) => void) => void
    ) => {
      const needle = val.toLowerCase()

      if (props.filterCallback) {
        await props.filterCallback(val)
      }

      update(
        async () => {
          if (!needle) {
            filteredOptions.value = props.options.slice()
          }

          filteredOptions.value = props.options.slice().filter((v) => {
            let label = ''
            if (props.optionLabel && typeof v !== 'string') {
              if (typeof props.optionLabel !== 'string') {
                label = props.optionLabel(v)
              } else {
                label = v[props.optionLabel]
              }
            } else if (!props.optionLabel && typeof v === 'string') {
              label = v
            }

            return label.toLowerCase().indexOf(needle) > -1
          })
        },
        (ref) => {
          if (!props.filterAutoSelect || !ref.options) return
          if (
            val !== '' &&
            ref.options.length > 0 &&
            ref.getOptionIndex() === -1
          ) {
            const exactMatchIndex = ref.options.findIndex((option) => {
              return val.toUpperCase() === ref.getOptionLabel(option)
            })

            if (exactMatchIndex > 0) {
              ref.moveOptionSelection(exactMatchIndex + 1, true) // focus the exact matched selectable option and do not update the input-value
            } else {
              ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
            }
            ref.toggleOption(ref.options[ref.getOptionIndex()], true) // toggle the focused option
          }
        }
      )
    }

    return {
      filterFn,
      value,
      filteredOptions,
    }
  },
})
