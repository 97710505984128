import { instance } from '@/services/avik-api-gateway'
import { SearchDeviation } from '@/types/search-deviation'
import { AxiosResponse, CancelToken } from 'axios'

export interface SearchDeviationParams {
  tnr: number
  date: string
}

export function searchDeviation(
  params: SearchDeviationParams,
  cancelToken: CancelToken
): Promise<AxiosResponse<SearchDeviation>> {
  return instance.get(`/search/deviation`, {
    cancelToken,
    params,
  })
}
