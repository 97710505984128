import { instance } from '@/services/avik-api-gateway'
import { DeviationDisruption } from '@/types/deviation-disruption'
import { AxiosResponse } from 'axios'

export function updateDeviationDisruption(
  body: Partial<DeviationDisruption>,
  deviationDisruptionUuid: string
): Promise<AxiosResponse<DeviationDisruption>> {
  return instance.put(`/deviation-disruption/${deviationDisruptionUuid}`, body)
}
