<template>
  <div class="row">
    <div class="col q-pa-md">
      <q-input outlined square autogrow v-model="model" :label="label" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'DeviationDescriptionInput',

  props: {
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: () => 'Kommentar (Specifikt för detta tåg)',
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const model = ref(props.modelValue)

    watch(model, (value) => {
      emit('update:modelValue', value)
    })

    return {
      model,
    }
  },
})
</script>
