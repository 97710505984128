import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { DeviationDelayProposal } from '@/types/deviation-delay-proposal'
import { getDeviationDelayProposals } from '@/api/deviation-delay-proposal/getDeviationDelayProposals'
import { useStatusBadgeCount } from './useStatusBadgeCount'

interface UseDeviationVehicleProposal {
  data: Ref<DeviationDelayProposal[]>
  fetchAll: (params: FetchAllParams) => Promise<DeviationDelayProposal[]>
  loading: ComputedRef<Loading>
}

interface FetchAllParams {
  from: string
  to: string
}

const data = ref<DeviationDelayProposal[]>([])
const { state } = useLoading()

export function useDeviationDelayProposal(): UseDeviationVehicleProposal {
  const { data: statusBadge } = useStatusBadgeCount()
  async function fetchAll(params: FetchAllParams) {
    state.getAll = true
    return new Promise<DeviationDelayProposal[]>((resolve, reject) => {
      getDeviationDelayProposals(params)
        .then(({ data: deviationDelayProposals }) => {
          if (deviationDelayProposals !== undefined) {
            state.getAll = false
          }
          data.value = deviationDelayProposals || []
          statusBadge.value.activeProposals.deviationDelay = data.value.length
          resolve(deviationDelayProposals || [])
        })
        .catch(reject)
    })
  }

  return {
    data,
    fetchAll,
    loading: computed(() => state),
  }
}
