import { instance } from '@/services/avik-api-gateway'
import { DeviationCrew } from '@/types/deviation-crew'
import { AxiosResponse } from 'axios'

export function updateDeviationCrew(
  body: Partial<DeviationCrew>,
  deviationCrewUuid: string
): Promise<AxiosResponse<DeviationCrew>> {
  return instance.put(`/deviation-crew/${deviationCrewUuid}`, body)
}

export function updateDeviationCrewDistance(
  deviationCrewUuid: string,
  body: { distance: number }
): Promise<AxiosResponse<DeviationCrew>> {
  return instance.put(`/deviation-crew/${deviationCrewUuid}/distance`, body)
}
