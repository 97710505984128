
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'DeviationDescriptionInput',

  props: {
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: () => 'Kommentar (Specifikt för detta tåg)',
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const model = ref(props.modelValue)

    watch(model, (value) => {
      emit('update:modelValue', value)
    })

    return {
      model,
    }
  },
})
