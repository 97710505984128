import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { useEvent } from '@/composable/useEvent'
import { addDays, format, sub } from 'date-fns'
import { DeviationDisruption } from '@/types/deviation-disruption'
import { getDeviationDisruption } from '@/api/deviation-disruption/getDeviationDisruption'
import { createDeviationDisruption } from '@/api/deviation-disruption/createDeviationDisruption'
import { updateDeviationDisruption } from '@/api/deviation-disruption/updateDeviationDisruption'
import { deleteDeviationDisruption } from '@/api/deviation-disruption/deleteDeviationDisruption'

interface UseDeviationDisruption {
  data: Ref<DeviationDisruption[]>
  remove: (uuid: string) => Promise<null>
  add: (body: Partial<DeviationDisruption>) => Promise<DeviationDisruption>
  update: (body: Partial<DeviationDisruption>) => Promise<DeviationDisruption>
  loading: ComputedRef<Loading>
  fetchAll: (params: FetchAllParams) => Promise<DeviationDisruption[]>
  period: Ref<Period>
  setDefaultPeriod: () => void
}

interface FetchAllParams {
  from: string
  to: string
}

interface Period {
  from: string
  to: string
}

function createDefaultPeriod() {
  return {
    from: format(sub(new Date(), { days: 5 }), 'yyyy-MM-dd'),
    to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  }
}

const period = ref<Period>(createDefaultPeriod())
const data = ref<DeviationDisruption[]>([])
const { state } = useLoading()

export function useDeviationDisruption(): UseDeviationDisruption {
  const { data: event } = useEvent()

  function setDefaultPeriod() {
    period.value = createDefaultPeriod()
  }

  async function fetchAll(fetchParams: FetchAllParams) {
    state.getAll = true
    return new Promise<DeviationDisruption[]>((resolve, reject) => {
      getDeviationDisruption(fetchParams)
        .then(({ data: deviationDisruptions }) => {
          data.value = deviationDisruptions
          resolve(deviationDisruptions)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteDeviationDisruption(uuid)
        .then(() => {
          if (event.value) {
            event.value.deviationDisruptions =
              event.value.deviationDisruptions.filter(
                (item) => item.uuid !== uuid
              )
          }
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: Partial<DeviationDisruption>) {
    state.create = true
    return new Promise<DeviationDisruption>((resolve, reject) => {
      createDeviationDisruption(body)
        .then(({ data: deviationDisruption }) => {
          if (event.value) {
            event.value.deviationDisruptions.push({
              ...deviationDisruption,
              activity: body?.activity || '',
            })
          }
          data.value.push({
            ...deviationDisruption,
            activity: body?.activity || '',
            eventName: body.eventName || null,
            eventId: body.eventId || null,
            eventIsOpen: body.eventIsOpen || null,
            eventDate: body.eventDate || null,
          })
          resolve(deviationDisruption)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Partial<DeviationDisruption>) {
    state.update = true

    const parsedBody = Object.assign({}, body)
    if (parsedBody.uuid) delete parsedBody.uuid

    return new Promise<DeviationDisruption>((resolve, reject) => {
      updateDeviationDisruption(parsedBody, body?.uuid || 'missing')
        .then(({ data: updatedDeviationDisruption }) => {
          if (event.value) {
            event.value.deviationDisruptions =
              event.value.deviationDisruptions.map((deviationDisruption) => {
                if (deviationDisruption.uuid === body?.uuid) {
                  return {
                    ...updatedDeviationDisruption,
                    activity: body?.activity || '',
                  }
                }

                return deviationDisruption
              })
          }

          data.value = data.value.map((deviationDisruption) => {
            if (deviationDisruption.uuid === body?.uuid) {
              return {
                ...updatedDeviationDisruption,
                activity: body?.activity || '',
                eventName: body.eventName || null,
                eventId: body.eventId || null,
                eventIsOpen: body.eventIsOpen || null,
                eventDate: body.eventDate || null,
              }
            }

            return deviationDisruption
          })

          resolve(updatedDeviationDisruption)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  return {
    data,
    remove,
    add,
    update,
    loading: computed(() => state),
    fetchAll,
    period,
    setDefaultPeriod,
  }
}
