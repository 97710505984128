import { computed, ComputedRef, Ref, ref } from 'vue'
import { SearchTrain } from '@/types/search-train'
import {
  searchTrain,
  SearchTrainParams,
  searchTrainDirect,
} from '@/api/search/searchTrain'
import { searchDeviation } from '@/api/search/searchDeviation'
import axios, { CancelTokenSource } from 'axios'
import { SearchTrainPlace } from '@/types/search-train-place'
import { searchTrainComposition } from '@/api/search/trainComposition'
import orderBy from 'lodash.orderby'
import { SearchTrainComposition } from '@/types/search-train-composition'
import { SearchDeviation } from '@/types/search-deviation'

const CancelToken = axios.CancelToken

interface UseSearchTrain {
  data: Ref<SearchTrain | null>
  searchDirect: (
    params: SearchTrainParams
  ) => Promise<SearchTrain | null | number>
  search: (
    params: SearchTrainParams,
    skipSearchTrainComposition?: boolean
  ) => Promise<SearchTrain | null>
  loading: Ref<boolean>
  deviations: Ref<SearchDeviation>
  placesDeparture: ComputedRef<SearchTrainPlace[]>
  placesArrival: ComputedRef<SearchTrainPlace[]>
  vehicleComposition: Ref<SearchTrainComposition>
}

const vehicleComposition = ref<SearchTrainComposition>({
  actualCompositions: [],
  expectedCompositions: [],
})

export function useSearchTrain(): UseSearchTrain {
  const data = ref<SearchTrain | null>(null)
  const deviations = ref<SearchDeviation>({
    deviationCancel: [],
    deviationCrew: [],
    deviationDelay: [],
    deviationVehicle: [],
  })

  const loading = ref(false)

  let cancelToken: CancelTokenSource = CancelToken.source()

  async function searchDirect(params: SearchTrainParams) {
    if (isNaN(params.tnr)) return null
    return searchTrainDirect(params).then(({ data: searchTrainData }) => {
      if (!searchTrainData || !searchTrainData.places.length) {
        return null
      }

      const path = searchTrainData.paths.find(
        (x) => x.technicalNumber === params.tnr
      )

      const pathPublicnumberIsTrn = searchTrainData.paths.find(
        (x) => x.trainNumber === params.tnr
      )

      if (
        pathPublicnumberIsTrn &&
        pathPublicnumberIsTrn.publicNumber !== params.tnr
      ) {
        return pathPublicnumberIsTrn.publicNumber
      } else if (path && path.publicNumber !== params.tnr) {
        return path.publicNumber
      } else {
        return searchTrainData
      }
    })
  }

  async function search(
    params: SearchTrainParams,
    skipSearchTrainComposition = false
  ) {
    loading.value = true

    cancelToken.cancel('Föregåenede sökning avbröts av train/search')
    cancelToken = CancelToken.source()
    return new Promise<SearchTrain | null>((resolve, reject) => {
      if (params.tnr === 0) {
        loading.value = false
        data.value = null
        return resolve(null)
      }
      if (isNaN(params.tnr)) return resolve(null)
      if (!cancelToken) return resolve(null)
      searchTrain(params, cancelToken.token)
        .then(({ data: searchTrainData }) => {
          if (!searchTrainData || !searchTrainData.places.length) {
            data.value = null
            return resolve(null)
          }

          // set search trainnumber to publicnumber
          const path = searchTrainData.paths.find(
            (x) => x.technicalNumber === params.tnr
          )

          const pathPublicnumberIsTrn = searchTrainData.paths.find(
            (x) => x.trainNumber === params.tnr
          )

          if (
            pathPublicnumberIsTrn &&
            pathPublicnumberIsTrn.publicNumber !== params.tnr
          ) {
            throw new Error(pathPublicnumberIsTrn.publicNumber.toString())
          } else if (path && path.publicNumber !== params.tnr) {
            throw new Error(path.publicNumber.toString())
          } else {
            data.value = searchTrainData
            return resolve(searchTrainData)
          }
        })
        .then(() => {
          if (skipSearchTrainComposition) {
            return null
          }
          return searchTrainComposition(params)
        })
        .then((res) => {
          if (res === null) return
          const { data } = res
          vehicleComposition.value.actualCompositions = data.actualCompositions
          vehicleComposition.value.expectedCompositions =
            data.expectedCompositions
        })
        .then(() => {
          return searchDeviation(params, cancelToken.token)
        })
        .then(({ data }) => {
          deviations.value = data
        })
        .catch(reject)
        .finally(() => {
          loading.value = false
        })
    })
  }

  const placesArrival = computed(() => {
    if (!data.value) return []

    const ank = data.value.places
      .filter((place) => place.activityType === 'ank')
      .slice()
      .map((x) => Object.assign({}, x))
    const sortedAnk = Object.values(
      orderBy(ank, ['advertisedTimeAtLocation'], ['asc']).reduce<{
        [key: string]: SearchTrainPlace
      }>((acc, place) => {
        if (!acc[`${place.location}-${place.advertisedTimeAtLocation}`]) {
          acc[`${place.location}-${place.advertisedTimeAtLocation}`] = place
        }

        return acc
      }, {})
    )

    return sortedAnk
  })

  const placesDeparture = computed(() => {
    if (!data.value) return []
    const avg = data.value.places
      .filter((place) => place.activityType === 'avg')
      .slice()
      .map((x) => Object.assign({}, x))

    const sortedAvg = Object.values(
      orderBy(avg, ['advertisedTimeAtLocation'], ['asc']).reduce<{
        [key: string]: SearchTrainPlace
      }>((acc, place) => {
        if (!acc[`${place.location}-${place.advertisedTimeAtLocation}`]) {
          acc[`${place.location}-${place.advertisedTimeAtLocation}`] = place
        }

        return acc
      }, {})
    )

    return sortedAvg
  })

  return {
    data,
    search,
    loading,
    placesArrival,
    placesDeparture,
    vehicleComposition,
    deviations,
    searchDirect,
  }
}
