import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { DeviationVehicleProposal } from '@/types/deviation-vehicle-proposal'
import { getDeviationVehicleProposals } from '@/api/deviation-vehicle-proposal/getDeviationVehicleProposals'
import { useStatusBadgeCount } from './useStatusBadgeCount'
import { addDays, format, sub } from 'date-fns'

interface UseDeviationVehicleProposal {
  data: Ref<DeviationVehicleProposal[]>
  fetchAll: (params: FetchAllParams) => Promise<DeviationVehicleProposal[]>
  loading: ComputedRef<Loading>
}

interface FetchAllParams {
  from: string
  to: string
}

const data = ref<DeviationVehicleProposal[]>([])
const { state } = useLoading()

export function useDeviationVehicleProposal(): UseDeviationVehicleProposal {
  const { data: statusBadge } = useStatusBadgeCount()
  const period = {
    from: format(sub(new Date(), { days: 5 }), 'yyyy-MM-dd'),
    to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  }
  async function fetchAll(_period?: { from: string; to: string }) {
    state.getAll = true
    return new Promise<DeviationVehicleProposal[]>((resolve, reject) => {
      getDeviationVehicleProposals(_period || period)
        .then(({ data: deviationVehicleProposals }) => {
          if (deviationVehicleProposals !== undefined) {
            state.getAll = false
          }
          data.value = deviationVehicleProposals || []
          statusBadge.value.activeProposals.deviationVehicle = data.value.length
          resolve(deviationVehicleProposals || [])
        })
        .catch(reject)
    })
  }

  return {
    data,
    fetchAll,
    loading: computed(() => state),
  }
}
