import { instance } from '@/services/avik-api-gateway'
import { SearchTrainComposition } from '@/types/search-train-composition'
import { AxiosResponse } from 'axios'

export interface SearchTrainCompostionParams {
  tnr: number
  date: string
}

export function searchTrainComposition(
  params: SearchTrainCompostionParams
): Promise<AxiosResponse<SearchTrainComposition>> {
  return instance.get(`/search/vehicle-composition`, {
    params,
  })
}
