import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { useEvent } from '@/composable/useEvent'
import { DeviationCrew } from '@/types/deviation-crew'
import { deleteDeviationCrew } from '@/api/deviation-crew/deleteDeviationCrew'
import { createDeviationCrew } from '@/api/deviation-crew/createDeviationCrew'
import { updateDeviationCrew } from '@/api/deviation-crew/updateDeviationCrew'
import { addDays, format, sub } from 'date-fns'
import { getDeviationCrews } from '@/api/deviation-crew/getDeviationCrews'

interface UseDeviationCrew {
  data: Ref<DeviationCrew[]>
  remove: (uuid: string) => Promise<null>
  add: (body: Partial<DeviationCrew>) => Promise<DeviationCrew>
  update: (body: Partial<DeviationCrew>) => Promise<DeviationCrew>
  loading: ComputedRef<Loading>
  fetchAll: (params: FetchAllParams) => Promise<DeviationCrew[]>
  period: Ref<Period>
  setDefaultPeriod: () => void
}

interface FetchAllParams {
  from: string
  to: string
}

interface Period {
  from: string
  to: string
}

function createDefaultPeriod() {
  return {
    from: format(sub(new Date(), { days: 5 }), 'yyyy-MM-dd'),
    to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  }
}

const period = ref<Period>(createDefaultPeriod())
const data = ref<DeviationCrew[]>([])
const { state } = useLoading()

export function useDeviationCrew(): UseDeviationCrew {
  const { data: event } = useEvent()

  function setDefaultPeriod() {
    period.value = createDefaultPeriod()
  }

  async function fetchAll(fetchParams: FetchAllParams) {
    state.getAll = true
    return new Promise<DeviationCrew[]>((resolve, reject) => {
      getDeviationCrews(fetchParams)
        .then(({ data: deviationCrews }) => {
          data.value = deviationCrews
          resolve(deviationCrews)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteDeviationCrew(uuid)
        .then(() => {
          if (event.value) {
            event.value.deviationCrews = event.value.deviationCrews.filter(
              (item) => item.uuid !== uuid
            )
          }
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: Partial<DeviationCrew>) {
    state.create = true
    return new Promise<DeviationCrew>((resolve, reject) => {
      createDeviationCrew(body)
        .then(({ data: deviationCrew }) => {
          if (event.value) {
            event.value.deviationCrews.push(deviationCrew)
          }
          data.value.push({
            ...deviationCrew,
            eventName: body.eventName || null,
            eventId: body.eventId || null,
            eventIsOpen: body.eventIsOpen || null,
            eventDate: body.eventDate || null,
          })
          resolve(deviationCrew)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Partial<DeviationCrew>) {
    state.update = true

    const parsedBody = Object.assign({}, body)
    if (parsedBody.uuid) delete parsedBody.uuid

    return new Promise<DeviationCrew>((resolve, reject) => {
      updateDeviationCrew(parsedBody, body?.uuid || 'missing')
        .then(({ data: updateDeviationCrew }) => {
          if (event.value) {
            event.value.deviationCrews = event.value.deviationCrews.map(
              (deviationCrew) => {
                if (deviationCrew.uuid === body?.uuid) {
                  return updateDeviationCrew
                }

                return deviationCrew
              }
            )
          }

          data.value = data.value.map((deviationCrew) => {
            if (deviationCrew.uuid === body?.uuid) {
              return {
                ...updateDeviationCrew,
                eventName: body.eventName || null,
                eventId: body.eventId || null,
                eventIsOpen: body.eventIsOpen || null,
                eventDate: body.eventDate || null,
              }
            }

            return deviationCrew
          })

          resolve(updateDeviationCrew)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  return {
    data,
    remove,
    add,
    update,
    loading: computed(() => state),
    period,
    setDefaultPeriod,
    fetchAll,
  }
}
