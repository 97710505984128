import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { DeviationCrew } from '@/types/deviation-crew'

interface FetchParams {
  from: string
  to: string
}

export function getDeviationCrews(
  params: FetchParams
): Promise<AxiosResponse<DeviationCrew[]>> {
  return instance.get(`/deviation-crew`, { params })
}
