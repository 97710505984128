import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { DeviationDelay } from '@/types/deviation-delay'

interface FetchParams {
  from: string
  to: string
}

let abortController: AbortController

export function getDeviationDelays(
  params: FetchParams
): Promise<AxiosResponse<DeviationDelay[]>> {
  if (abortController) {
    abortController.abort()
    abortController = new AbortController()
  } else {
    abortController = new AbortController()
  }
  return instance.get(`/deviation-delay`, {
    params,
    signal: abortController.signal,
  })
}
