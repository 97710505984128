import { instance } from '@/services/avik-api-gateway'
import { DeviationDisruption } from '@/types/deviation-disruption'
import { AxiosResponse } from 'axios'

interface FetchParams {
  from: string
  to: string
}

export function getDeviationDisruption(
  params: FetchParams
): Promise<AxiosResponse<DeviationDisruption[]>> {
  return instance.get(`/deviation-disruption`, { params })
}
