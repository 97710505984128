import { render } from "./AppFieldSelect.vue?vue&type=template&id=2889ef63"
import script from "./AppFieldSelect.vue?vue&type=script&lang=ts"
export * from "./AppFieldSelect.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QItem,QItemSection});
