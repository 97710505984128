import { instance } from '@/services/avik-api-gateway'
import { DeviationCrew } from '@/types/deviation-crew'

import { AxiosResponse } from 'axios'

export function deleteDeviationCrew(
  deviationCrewUuid: string
): Promise<AxiosResponse<DeviationCrew>> {
  return instance.delete(`/deviation-crew/${deviationCrewUuid}`)
}
