import { ExportParams } from '@/services/export-excel'
import QTable from 'quasar/src/components/table/QTable.js';

interface Params<T> {
  name: string
  table: T[]
  columns: QTable['columns']
}

export const buildExcelSheetFromTable = <T>({
  name,
  table,
  columns,
}: Params<T>): ExportParams => {
  const exportData = {
    name,
    sheets: [
      {
        name,
        data: table.map((item) => {
          return columns?.reduce<{ [name: string]: unknown }>((acc, col) => {
            const value =
              typeof col.field === 'function'
                ? col.field(item)
                : item[col.field as keyof T] || item[col.name as keyof T]

            if (col.format) {
              acc[col.label] = col.format(value, null)
            } else {
              acc[col.label] = value || ''
            }

            return acc
          }, {})
        }),
      },
    ],
  }

  return exportData
}
