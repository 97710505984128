import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { DeviationDelay } from '@/types/deviation-delay'
import { DeviationDelayModel } from '@/composable/useDeviationDelay'

export function createDeviationDelay(
  body: DeviationDelayModel
): Promise<AxiosResponse<DeviationDelay>> {
  return instance.post(`/deviation-delay`, body)
}
