
import { required as requiredRule } from '@/common/formValidationRules'
import { computed, defineComponent, ref, watch } from 'vue'
import AppFieldSelect from '@/components/AppFieldSelect.vue'
import { useEvent } from '@/composable/useEvent'

export default defineComponent({
  name: 'DeviationEventInput',

  components: {
    AppFieldSelect,
  },

  props: {
    eventUuid: String,
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { list, loading, fetch, data, fetchAll, period } = useEvent()

    fetchAll(period.value).then(() => {
      if (
        !model.value.eventUuid &&
        events.value &&
        events.value.length === 1 &&
        events.value[0]
      ) {
        model.value.eventUuid = events.value[0].uuid
      }
    })

    if (props.eventUuid) {
      data.value = null
      fetch(props.eventUuid)
    }

    const model = ref(Object.assign({}, props.modelValue))

    const events = computed(() => {
      const openEvents = list.value.filter((x) => x.open && !x.deleted)
      if (
        !props.eventUuid ||
        openEvents.some((item) => item.uuid === props.eventUuid)
      ) {
        return openEvents
      }

      return [...openEvents, data.value]
    })

    watch(
      () => model.value,
      (value) => {
        const event =
          list.value.find((event) => event && event.uuid === value.eventUuid) ||
          null
        emit(
          'update:modelValue',
          Object.assign(
            {},
            {
              ...props.modelValue,
              eventUuid: value.eventUuid,
              eventName: event?.description || null,
              eventId: event?.id || null,
              eventIsOpen: event?.open || null,
              eventDate: event?.date || null,
            }
          )
        )
      },
      { deep: true, immediate: true }
    )

    return {
      model,
      events,
      loading,
      requiredRule,
    }
  },
})
