import { instance } from '@/services/avik-api-gateway'
import { DeviationVehicleProposal } from '@/types/deviation-vehicle-proposal'
import { AxiosResponse } from 'axios'

interface FetchParams {
  from: string
  to: string
}

let abortController: AbortController

export function getDeviationVehicleProposals(
  params: FetchParams
): Promise<AxiosResponse<DeviationVehicleProposal[]>> {
  if (abortController) {
    abortController.abort()
    abortController = new AbortController()
  } else {
    abortController = new AbortController()
  }
  return instance.get(`/deviation-vehicle-proposal`, {
    params,
    signal: abortController.signal,
  })
}
